import React from "react";
import { Link } from "gatsby";
import * as R from 'ramda';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import Content from '../components/services/content';
import HeroPage from '../components/services/hero';
import CommercialSealcoatContent from '../components/services/commercial/sealcoatContent';

const IndexPage = ({
  pageContext: {
    servicePage
  }
}) => {
  const homePageSeoTitle = "C&R Asphalt Animals |  Lexington's Top Rated Paving Company | Serving Lexington, Louisville, Georgetown, Versailles, Frankfort, Winchester";
  const similarPage = servicePage.similarPage[0];
  return (
    <Layout headerType={servicePage.slug.current === 'mulch' ? 'mulch' : 'default'}>
      <SEO title={homePageSeoTitle} seo={servicePage.seo?.[0]} />
      <HeroPage
        page={servicePage.slug.current}
        title={servicePage.title}
        subtitle={servicePage.subtitle}
        cta={servicePage.primaryCta}
        noCta={!servicePage.primaryCta ?? servicePage.primaryCta === ''}
        image={servicePage.mainImage}
        ctaSecondary={similarPage && `Looking for ${similarPage?.title}? Click here.`}
        ctaSecondaryLinkTo={`/${similarPage?.slug?.current}/`} />
      {/*<CommercialSealcoatContent />*/}
      <Content content={servicePage._rawBody} />
    </Layout>
  );
};

const mapStateToProps = state => {
  return {  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({
    }, dispatch),
  }
};

const Index = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage);

export default Index;
